import React, { useEffect, useState } from "react";
import { Header, DataCards, CustomerTable, Nav } from "components/Admin";
import { useQuery } from "@tanstack/react-query";
import { useCompanies, useCompaniesHeaders } from "hooks/users";
import Pagination from "components/common/pagination/Pagination";

function CustomerList() {
  const pageSize = 10;
  const [page, setPage] = useState(1);
  const {
    isError,
    isLoading,
    data: customers,
    refetch: refetchList,
  } = useCompanies({ pageSize: pageSize, pageNumber: page, orderDir: "ASC" });
  const {
    data: headerData,
    refetch: refetchNumbers,
    isLoading: headerloader,
  } = useCompaniesHeaders();
  console.log("headerss", headerData);
  useEffect(() => {
    refetchList({ pageNumber: page });
    refetchNumbers();
  }, [page]);
  console.log("customers", customers);
  return (
    <div className="row">
      <div className="col-2">
        <Nav />
      </div>
      <div className="col-10 px-5 pt-4">
        <Header />
        <DataCards headerData={headerloader ? "" : headerData?.data} />
        <CustomerTable customers={customers?.data?.q} />
        <Pagination
          totalPages={Math.ceil(customers?.data?.count / pageSize)}
          page={page}
          setPage={setPage}
        />
      </div>
    </div>
  );
}

export default CustomerList;
