import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Input from "../../components/common/input/Input";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "constants";
import { useNavigate } from "react-router-dom";
const phoneSchema = yup
  .string()
  .test(
    "is-numeric",
    "Phone Number must contain only numeric characters",
    (value) => /^(\+)?[0-9]+$/.test(value)
  );

const validationSchema = yup.object({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  companyName: yup.string().required("Company Name is required"),
  phoneNumber: phoneSchema.required("Phone Number is required"),
  username: yup.string().required("Username is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  DNSPrefix: yup
    .string()
    .required("DNS Name is required")
    .max(100, "DNS Name must be at most 100 characters long"),
});

function NewCustomer() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      phoneNumber: "",
      username: "",
      email: "",
      DNSPrefix: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const createCustomer = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/company/CreateCompany`, fields);
    },
    onSuccess: async (data) => {
      toast.error("Company Created Succesfully", {
        duration: 5000,
        style: {
          background: "rgba(0, 128, 0, 0.5)",
          color: "white",
        },
      });
      navigate("/admin/customer-list");
    },
    onError: (error) => {
      toast.error("Invalid Credentials", {
        duration: 10000,
        style: {
          background: "#FFEAEA",
        },
      });
      setIsLoading(false);
    },
  });

  const handleSubmit = async (data) => {
    const {
      firstName,
      lastName,
      companyName,
      phoneNumber,
      username,
      email,
      DNSPrefix,
    } = data;
    const customerData = {
      email,
      companyName,
      userName: username,
      firstName,
      lastName,
      phoneNumber,
      DNSPrefix,
    };
    setIsLoading(true);
    customerData.DNSPrefix = customerData.DNSPrefix + ".aiducator.ae";
    createCustomer.mutate(customerData);
  };

  return (
    <div className="new-customer-container min-vh-100">
      <img className="login-logo" src="/svg/logo.svg" />
      <div className="new-customer-form normal-card">
        <div className="new-customer-grid">
          {" "}
          <Input
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            placeholder="First Name"
            onBlur={formik.handleBlur}
            error={
              formik.touched.firstName && formik.errors.firstName
                ? formik.errors.firstName
                : null
            }
          />
          <Input
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            placeholder="Last Name"
            onBlur={formik.handleBlur}
            error={
              formik.touched.lastName && formik.errors.lastName
                ? formik.errors.lastName
                : null
            }
          />{" "}
          <Input
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            placeholder="Company Name"
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && formik.errors.companyName
                ? formik.errors.companyName
                : null
            }
          />{" "}
          <Input
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            placeholder="Phone Number"
            onBlur={formik.handleBlur}
            error={
              formik.touched.phoneNumber && formik.errors.phoneNumber
                ? formik.errors.phoneNumber
                : null
            }
          />{" "}
          <Input
            name="username"
            value={formik.values.username}
            onChange={formik.handleChange}
            placeholder="Username"
            onBlur={formik.handleBlur}
            error={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
          />
          <Input
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            placeholder="Email"
            onBlur={formik.handleBlur}
            type="email"
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : null
            }
          />
        </div>
        <div className="dns-input">
          <Input
            name="DNSPrefix"
            value={formik.values.DNSPrefix}
            onChange={formik.handleChange}
            placeholder="DNS Name"
            onBlur={formik.handleBlur}
            error={
              formik.touched.DNSPrefix && formik.errors.DNSPrefix
                ? formik.errors.DNSPrefix
                : null
            }
          />
          <span>.aiducator.ae</span>
        </div>
        <button
          className="btn main-btn rounded-pill w-100 p-2 mt-3"
          onClick={formik.handleSubmit}
          type="button"
        >
          {isLoading ? "Loading ..." : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default NewCustomer;
