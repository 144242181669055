import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { PublicRoute, PrivateRoute } from "routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
  useQueryClient,
} from "@tanstack/react-query";
import { Login } from "./pages";
import { CustomerDetails, CustomerList } from "pages/Admin";
import toast, { Toaster } from "react-hot-toast";
import "App.css";
import NewCustomer from "pages/NewCustomer";

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: async (error) => {
      if (401 === error?.response?.status) {
        localStorage.removeItem("token");
        toast.dismiss();
        toast.error("Session Expired. Please Login Again!", {
          duration: 10000,
          style: {
            background: "#FFEAEA",
          },
        });
      }
    },
  }),
  mutations: {},
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position="top-center" reverseOrder={false} />
      <Router>
        <Routes>
          <Route path="/admin" element={<PrivateRoute />}>
            <Route path="customer-list" element={<CustomerList />} />
            <Route path="customer-details" element={<CustomerDetails />} />
            <Route path="customer-form" element={<NewCustomer />} />
          </Route>
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Login />} />
          </Route>
        </Routes>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
