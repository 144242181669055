import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "../constants";
import { cleanToken } from "utils";

const fetchAuthUser = async () => {
  return await axios.get(API_URL + `/User/GetAuthenticatedUser`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchCompanyUsers = async (data) => {
  return await axios.get(
    API_URL + `/User/GetAllUsers/${data?.pageSize}/${data?.pageNumber}`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};
const fetchCompanies = async (data) => {
  return await axios.get(
    API_URL +
      `/Company/GetAllCompanies/${data?.pageNumber}/${data?.pageSize}?orderDir=asc`,
    {
      headers: {
        Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
      },
    }
  );
};
const fetchCompaniesHeaders = async (data) => {
  return await axios.get(API_URL + "/Company/GetCompanyNumbers", {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchUserImage = async (userId) => {
  return await axios.get(API_URL + `/User/GetUserImage/${userId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const fetchUserUsage = async (userId) => {
  return await axios.get(API_URL + `/User/GetUsageByUserId?userId=${userId}`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
  });
};

const exportUsersTable = async () => {
  return await axios.get(API_URL + `/User/ExportUsers`, {
    headers: {
      Authorization: `Bearer ${cleanToken(localStorage.getItem("token"))}`,
    },
    responseType: "blob",
  });
};

export const useFetchCompanyUsers = (data) => {
  return useQuery({
    queryKey: ["users"],
    queryFn: () => fetchCompanyUsers(data),
    enabled: false,
  });
};
export const useCompanies = (data) => {
  return useQuery({
    queryKey: ["company"],
    queryFn: () => fetchCompanies(data),
    enabled: true,
  });
};
export const useCompaniesHeaders = () => {
  return useQuery({
    queryKey: ["companyHeaders"],
    queryFn: () => fetchCompaniesHeaders(),
    enabled: true,
  });
};
export const useFetchAuthUser = () => {
  return useQuery({
    queryKey: ["authUser"],
    queryFn: () => fetchAuthUser(),
    enabled: true,
  });
};

export const useFetchUserImage = (userId) => {
  return useQuery({
    queryKey: ["userImage", userId],
    queryFn: () => fetchUserImage(userId),
    enabled: false,
  });
};

export const useExportUsersTable = () => {
  return useMutation(() => exportUsersTable());
};

export const useFetchUserUsage = (userId) => {
  return useQuery({
    queryKey: ["userUsage"],
    queryFn: () => fetchUserUsage(userId),
    enabled: false,
  });
};
