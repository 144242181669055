import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import CustomerTableRow from "./CustomerTableRow";

function CustomerTable({ customers }) {
  return (
    <div className="user-table mt-3">
      <div className="table-responsive-sm">
        <table className="table table-borderless tablecenter">
          <thead>
            <tr>
              <th className="table-header" scope="col">
                Company Name
              </th>
              {/* <th className="table-header headercenter" scope="col">
                Contact Person
              </th> */}
              <th className="table-header headercenter" scope="col">
                Email
              </th>
              <th className="table-header headercenter" scope="col">
                Mobile
              </th>
              <th className="table-header headercenter" scope="col">
                Plan
              </th>{" "}
              <th className="table-header headercenter" scope="col">
                Tokens
              </th>{" "}
              <th className="table-header headercenter" scope="col">
                Consumption
              </th>
              <th className="table-header headercenter" scope="col">
                Date Joined
              </th>
              <th className="table-header headercenter" scope="col">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {customers?.map((customer, index) => (
              <CustomerTableRow customer={customer} key={index} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default CustomerTable;
