import React from "react";

const Input = ({ name, value, onChange, onBlur, placeholder, error }) => {
  return (
    <div className="input-container">
      <input
        type="text"
        className="customer-form-input"
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        style={{ width: "100%" }}
      />
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default Input;
