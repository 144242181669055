import React, { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { API_URL } from "constants";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { isEmpty } from "lodash";

const validationSchema = yup.object({
  username: yup.string().required("Username is required"),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password can be at most 20 characters")
    .required("Password is required"),
});

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [url, setUrl] = useState("");

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      setUrl("aiducatorfe");
    } else {
      const currentURL = window.location.href;
      const match = currentURL.match(/\/\/([^/.]+)\./);

      if (match && match.length > 1) {
        const extractedPart = match[1];
        setUrl(extractedPart);
      } else {
        setUrl("");
      }
    }
  }, []);

  const login = useMutation({
    mutationFn: (fields) => {
      return axios.post(API_URL + `/auth/login`, fields);
    },
    onSuccess: async (data) => {
      localStorage.setItem("token", JSON.stringify(data.data.token));
      navigate("/admin/customer-list");
    },
    onError: (error) => {
      toast.error("Invalid Credentials", {
        duration: 10000,
        style: {
          background: "#FFEAEA",
        },
      });
      console.log("error: ", error);
    },
  });

  const handleSubmit = async (data) => {
    setIsLoading(true);
    login.mutate({
      username: data.username,
      password: data.password,
    });
  };

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      formik.handleSubmit();
    }
  };

  return (
    <div className="login-bg min-vh-100">
      <div className="d-flex justify-content-center">
        <div className="d-flex justify-content-center w-100 min-vh-100 flex-column align-items-center">
          <div
            className="login normal-card p-5 d-flex flex-column align-items-center"
            // style={{ backgroundColor: data?.data[0]?.loginBackgroundColor }}
          >
            <img className="login-logo" src="/svg/logo.svg" />
            {/* {!isEmpty(data?.data[0]?.sideBarLogo) ? (
              <img
                src={`data:${data?.data[0]?.sideBarLogoContentType};base64,${data?.data[0]?.sideBarLogo}`}
                className="w-50 mb-2"
              />
            ) : (
              <img className="w-50 mb-2" src="/images/ai-logo.svg" />
            )} */}
            <div className="form-group profile-field mb-3 mx-3 w-100">
              <label className="mb-2">Email</label>
              <input
                type="text"
                className="form-control profile-input"
                value={formik.values.username}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="username"
                placeholder="ana.abc.com"
              />
              {formik.touched.username && formik.errors.username ? (
                <div className="error-message">{formik.errors.username}</div>
              ) : null}
            </div>
            <div className="form-group profile-field mb-3 mx-3 w-100">
              <label className="mb-2">Password</label>
              <input
                type="password"
                className="form-control profile-input"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                name="password"
                onKeyDown={handleEnterKeyPress}
                placeholder="Enter Password"
              />
              {formik.touched.password && formik.errors.password ? (
                <div className="error-message">{formik.errors.password}</div>
              ) : null}
            </div>
            <div className="d-flex justify-content-between align-items-center w-100">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckChecked"
                />
                <label className="login-otherbtn">Remember me</label>
              </div>
              <button className="btn login-otherbtn">Forgot Password?</button>
            </div>
            <button
              className="btn main-btn rounded-pill w-100 p-2 mt-3"
              onClick={formik.handleSubmit}
              type="button"
              // style={{ backgroundColor: data?.data[0]?.loginButtonColor }}
            >
              {isLoading ? "Loading ..." : "Login"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
